/* eslint-disable */
const CountriesModal = {
    init: function () {
        this.modalTriggers = document.querySelectorAll('[data-lang-modal-trigger-js]');
        this.searchModal = document.querySelector('[data-lang-modal-js]');
        this.closeBtn = document.querySelector('[data-close-lang-modal-js]');
        this.langSearch = document.getElementById('langSearch');
        this.langListContainer = document.querySelector('.countries-list');
        this.langList = document.querySelectorAll('.countries-list .country-item');
        this.noResultsMessage = document.getElementById('noResultsMessage');
        this.mapContainer = document.getElementById("countries-map");
        this.popUp = document.querySelector('.map-language-popup');
        this.popUpLanguages = this.popUp.querySelector('.map-language-popup__languages');
        this.langModal = document.querySelector('.lang-modal');

        if (!this.modalTriggers.length || !this.searchModal || !this.langListContainer) return;

        this.initEvents();
        this.initMapInteractions();
    },

    initEvents: function () {
        this.modalTriggers.forEach(trigger =>
            trigger.addEventListener('click', () => this.openSearchModal())
        );

        if (this.langSearch) {
            this.langSearch.addEventListener('keyup', () => this.searchLang());
        }

        if (this.closeBtn) {
            this.closeBtn.addEventListener('click', () => this.closeSearchModal());
        }

        window.addEventListener('keydown', (e)=> this.handleGlobalKeys(e));
    },

    searchLang: function () {
        const searchValue = this.langSearch.value.toLowerCase();
        let found = false;

        this.langList.forEach(countryItem => {
            const title = countryItem.querySelector('.country-details-title');
            const langCodes = countryItem.querySelectorAll('.country-lang-list-link');

            let matches = title.innerHTML.toLowerCase().includes(searchValue);
            langCodes.forEach(link => {
                if (link.innerHTML.toLowerCase().includes(searchValue)) {
                    matches = true;
                }
            });

            countryItem.style.display = matches ? 'flex' : 'none';
            if (matches) found = true;
        });

        if (this.noResultsMessage) {
            this.noResultsMessage.style.display = found ? 'none' : 'block';
        }
    },

    openSearchModal: () => {
        document.documentElement.classList.add("overflowHiddenSearch");
        document.body.classList.add("overflowHiddenSearch", "langModalOpen");
    },

    closeSearchModal: () => {
        document.documentElement.classList.remove("overflowHiddenSearch");
        document.body.classList.remove("overflowHiddenSearch", "langModalOpen");
    },

    initMapInteractions: function () {
        if (this.langListContainer) {
            this.langListContainer.addEventListener('mouseover', (event) => {
                const item = event.target.closest('.country-item');
                if (!item || item.dataset.hovered) return;

                item.dataset.hovered = "true";
                const clonedItem = item.cloneNode(true); // Clone the full item
                clonedItem.querySelector('.country-lang-list').style.display = 'none';

                const id = item.getAttribute("data-map");
                if (id && clonedItem) this.fillMap(id, clonedItem);
            });

            this.langListContainer.addEventListener('mouseout', (event) => {
                const item = event.target.closest('.country-item');
                if (!item) return;

                delete item.dataset.hovered;
                const id = item.getAttribute("data-map");
                if (id) this.unFillMap(id);
            });
        }

        if (this.mapContainer) {
            this.mapContainer.addEventListener('mouseover', (event) => {
                const id = event.target.id || event.target.closest('g')?.id;
                if (!id || this.mapContainer.dataset.hovered === id) return;

                this.mapContainer.dataset.hovered = id;
                const listItem = document.querySelector(`.country-item[data-map="${id}"]`);

                if (!listItem) return;

                const clonedItem = listItem.cloneNode(true); // Clone full country item
                clonedItem.querySelector('.country-lang-list').style.display = 'none';
                this.fillMap(id, clonedItem);
            });

            this.mapContainer.addEventListener('mouseout', (event) => {
                const id = event.target.id || event.target.closest('g')?.id;
                if (id) {
                    delete this.mapContainer.dataset.hovered;
                    this.unFillMap(id);
                }
            });

            this.mapContainer.addEventListener('click', (event) => {
                const id = event.target.id || event.target.closest('g')?.id;
                if (!id) return;

                const listItem = document.querySelector(`.country-item[data-map="${id}"]`);
                if (listItem.classList.contains('has-multilang')) {
                    const languages = listItem?.cloneNode(true);
                    this.popUpLanguages.appendChild(languages);
                    setTimeout(() => {
                        this.popUp.classList.add('active');
                    }, 0)
                } else {
                    window.location.href = listItem.querySelector('a')?.href || window.location.href;
                }
            });
        }


        if (this.langModal) {
            this.langModal.addEventListener('click', (event) => {
                if (!this.popUp.classList.contains('active')) return;
                if (!event.target.closest('.map-language-popup') || event.target.closest('.map-language-popup__close')) {
                    this.closeLangModal();
                }
            })
        }
    },

    fillMap: function (id, clonedItem) {
        const country = document.getElementById(id);
        const map = document.getElementById("countries-map");
        const svgElement = map.querySelector("svg");

        if (!country || !map || !svgElement) return;

        // Remove existing tooltip if present
        let iconWrapper = map.querySelector(".country-map-element");
        if (!iconWrapper) {
            iconWrapper = document.createElement("div");
            iconWrapper.classList.add("country-map-element");
            map.appendChild(iconWrapper);
        }

        // Clear previous content and insert cloned item
        iconWrapper.innerHTML = '';
        iconWrapper.appendChild(clonedItem);

        // Positioning logic
        const countryRect = country.getBoundingClientRect();
        const mapRect = svgElement.getBoundingClientRect();

        const rightX = countryRect.right;
        const middleRightY = countryRect.top + countryRect.height / 2;

        const relativeRightXPercent = ((rightX - mapRect.left) / mapRect.width) * 100;
        const relativeMiddleRightYPercent = ((middleRightY - mapRect.top) / mapRect.height) * 100;

        Object.assign(iconWrapper.style, {
            top: `calc(${relativeMiddleRightYPercent}%)`,
            left: `calc(${relativeRightXPercent + 2}%)`,
        });

        country.classList.add('filled-up');
    },

    unFillMap: function (id) {
        const country = document.getElementById(id);
        const map = document.getElementById("countries-map");
        const iconWrapper = map?.querySelector('.country-map-element');

        if (country) {
            country.classList.remove('filled-up');
        }
        if (iconWrapper) iconWrapper.remove();
    },

    closeLangModal: function () {
        this.popUpLanguages.innerHTML = '';
        this.popUp.classList.remove('active');
    },

    handleGlobalKeys(e) {
        if(e.key === 'Escape' || e.key === "Esc") {
            if(document.body.classList.contains('langModalOpen')) {
                if(this.popUp.classList.contains('active')) {
                    this.closeLangModal();
                    return;
                }
                this.closeSearchModal();
            }
        }
    }
};

export default CountriesModal;

const scrollToTop = () => {
    const scrollProgress = document.querySelector('.top-btn');
    let pos = document.documentElement.scrollTop;

    if (pos > 300) {
        scrollProgress.style.opacity = '1';
        scrollProgress.style.visibility = 'visible';
    } else {
        scrollProgress.style.opacity = '0';
        scrollProgress.style.visibility = 'hidden';
    }

    scrollProgress.addEventListener('click', (e) => {
        e.preventDefault();
        document.documentElement.scrollTo({top: 0, behavior: 'smooth'});
    });
}

export default scrollToTop;

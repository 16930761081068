import Header from './parts/layout/header';
import Search from './parts/layout/search';
import CountriesModal from './parts/layout/countries-modal';
import AnchorsScroll from './parts/components/anchor-scroll';
import subscribeForms from './parts/components/subscribe-forms';
import CommonAccessibility from './parts/components/accessibility';
import Dropdowns from './parts/components/dropdown-nav';
import AppHeight from './parts/components/app-height';
import {debounce} from "./utils/delays";
import scrollToTop from "./parts/components/scrollToTop";

window.addEventListener('load', () => {
  AnchorsScroll.init();
  subscribeForms.init();
  Header.init();
  CommonAccessibility.init();
  Search.init();
  Dropdowns.init();
  AppHeight.init();
  CountriesModal.init();
});

window.addEventListener("scroll", debounce(scrollToTop, 100));
